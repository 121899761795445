<template>
    <div class="servicoCards d-flex flex-column justify-space-around">
        <div
            class="header"
            data-cy="content-header"
        >
            <span
                id="title"
                class="text-h4 font-weight-bold"
            >Olá!</span>
            <p
                id="subtitle"
                class="text-h6 font-weight-light grey--text text--darken-2"
            >
                Seja bem-vindo(a) à <span class="font-weight-bold">NUCO</span>
            </p>
        </div>
        <v-radio-group
            v-model="tipoServico"
            mandatory
        >
            <v-radio
                v-for="(item, index) in servicos"
                :id="item.id + '-radio'"
                :key="index"
                :data-cy="item.id + '-radio'"
                :value="item.id"
                outlined
            >
                <template v-slot:label>
                    <ServicoCard
                        :id="item.id"
                        :title="item.title"
                        :subtitle="item.subtitle"
                        :text="item.text"
                        :svg="item.svg"
                    />
                </template>
            </v-radio>
        </v-radio-group>
        <div>
            <p class="text-subtitle font-weight-light grey--text text--darken-3">
                Em breve ofereceremos serviço de
                <span
                    class="font-weight-light highlight"
                    data-content="Abertura de empresas"
                >Abertura de empresas</span>
            </p>
        </div>
        <v-container>
            <v-row
                dense
                justify="center"
                justify-sm="end"
            >
                <v-col cols="auto">
                    <v-btn
                        rounded
                        filled
                        color="primary"
                        data-cy="next-step"
                        height="61px"
                        class="next-step text-capitalize font-weight-light text-h6"
                        elevation="3"
                        x-large
                        @click.prevent="nextStep()"
                    >
                        Continuar
                        <v-icon right>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ServicoCard from "@/components/ServicoCard";
export default {
    name: "TipoServico",
    components: {
        ServicoCard,
    },
    data() {
        return {
            servicos: [
                {
                    title: "Contabilidade",
                    subtitle: "Contabilidade online, acessível e moderna",
                    svg: "visual-data.svg",
                    text:
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi inventore aliquam, explicabo sequi",
                    id: "contabilidade",
                },
            ],
        };
    },
    computed: {
        activeStep: {
            get() {
                return this.$store.getters["activeStep"];
            },
        },
        tipoServico: {
            get() {
                return this.$store.getters["usuario/tipoServico"];
            },
            set(value) {
                this.$store.commit("usuario/tipoServico", value);
            },
        },
    },
    methods: {
        nextStep() {
            return this.$store.commit("nextStep");
        },
    },
};
</script>

<style lang="scss" scoped>
span.highlight {
  z-index: 2;
  white-space: nowrap;
  position: relative;
  // background: lighten($theme-light-pink, 5%);
  color: lighten($theme-light-pink, 5%);
  padding: 0 5px;
  // color: transparent;
  // &::before {
  //     z-index: -1;
  //     content: '';
  //     position: absolute;
  //     background: #f9f821;
  //     background: lighten($theme-light-pink, 50%);
  //     color: white;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 50%;
  // }
  // &::after {
  //     z-index: -1;
  //     content: attr(data-content);
  //     position: absolute;
  //     color: #424242;
  //     top: auto;
  //     bottom: 12px;
  //     left: 0;
  //     width: 100%;
  //     height: 50%;
  // }
  // &::selection {
  //     color: white;
  //     background: #accef7;
  // }
}
</style>
