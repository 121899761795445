<template>
    <v-card
        :id="id + '-card'"
        outlined
        rounded="xl"
        class="card"
        :data-cy="id + '-card'"
        :class="{ active: tipoServico === id }"
        :ripple="{ class: 'primary--text' }"
        @click.prevent="selectServico(id)"
    >
        <v-card-title class="title">
            <h5 class="font-weight-medium text-h5">
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-subtitle class="subtitle">
            {{ subtitle }}
        </v-card-subtitle>
        <v-card-text class="content">
            <v-row>
                <v-col cols="auto">
                    <v-avatar
                        size="80"
                        class="mr-1"
                    >
                        <img
                            class="preco-svg"
                            :src="require(`@/assets/svg/${svg}`)"
                            alt=""
                        >
                    </v-avatar>
                </v-col>
                <v-col class="align-self-center">
                    {{ text }}
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "ServicoCard",
    props: {
        title: {
            type: String,
            default: "",
        },
        subtitle: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        "disabled-message": {
            type: String,
            default: "Em breve!",
        },
        svg: {
            default: "",
            type: String,
        },
        id: {
            default: "",
            type: String,
        },
    },
    computed: {
        tipoServico: {
            get() {
                return this.$store.getters["usuario/tipoServico"];
            },
        },
    },
    methods: {
        selectServico(servico) {
            this.$store.commit("usuario/tipoServico", servico);
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
  margin: 20px 0;
  &:hover,
  &.active {
    border: thin solid $theme-pink;
    box-shadow: $box-shadow-default;
  }
  &.active {
    &::before {
      background: $theme-pink;
      opacity: 0.05;
    }
  }
  &:focus::before {
    background: $theme-pink;
    opacity: 0.05;
  }
  &:before {
    border-radius: 20px;
  }
}
</style>
